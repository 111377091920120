<template>
    <div class="page page-sidebar bg-4">
        <notifications/>
        <lego-side-bar class="text-white bg-primary" @link-event="linkEvent" drop-down-animation="zoom" :items="menu"
                       :logo-text="'KLDB'" :colored="false" has-separator>
            <template #logo>
                <img src="../assets/img/logo/logo.png" class="my-3" alt="">
            </template>
        </lego-side-bar>
        <div class="page-content-outer">
            <div class="bg-3">
                <lego-nav-bar @link-event="linkEvent" logo-text="" drop-down-animation="bounce" hover-effect="0"
                              class="headerFixed nav container text-white bg-2" shadow="0" :items="menu1"/>
            </div>
            <div class="page-content py-5 px-3">
                <div class="thin-scrollbar">
                    <transition name="fade"
                                enter-active-class="animated fadeIn"
                                leave-active-class="animated fadeOut" mode="out-in">
                        <router-view>

                        </router-view>
                    </transition>
                </div>
            </div>
            <div class="bg-primary-900 p-3">
                © {{ 2021 }} KLDB
            </div>
        </div>
    </div>
</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import LegoSideBar from 'lego-framework/src/components/SideNavBar';
import getMenu from '../data/AdminMenu';
import getMenu1 from '../data/UserMenu';
import urls from '../data/urls';
import axios from 'secure-axios';

export default {
    components: {
        LegoNavBar,
        LegoSideBar
    },
    data () {
        return {
            menu: getMenu(),
            menu1: getMenu1(),
            value: ''
        };
    },
    methods: {
        linkEvent (name) {
            if (name === 'admin-logout') {
                localStorage.clear();
                axios.get(urls.auth.logout);
                this.$router.push('/admin/login/');
            }
        }
    },
    metaInfo: {
        titleTemplate: '%s - KLDB',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
