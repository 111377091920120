import store from '../store';

const getUserMenu = function () {
    const user = store.getters.currentUser;
    const userMenu = [
        {
            icon: 'fa fa-user',
            class: 'ml-auto',
            text: user.username,
            items: [
                {
                    text: 'Logout',
                    event: 'admin-logout'
                }

            ]
        }];
    // Do something similar for any number of roles
    if (user && user.is_superuser === true) {
        return [
            ...userMenu
        ];
    }
    return [
        ...userMenu
    ];
};
export default getUserMenu;
