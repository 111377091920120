import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: '',
            text: 'Home',
            class: 'fs--1',
            link: '/'
        },
        {
            text: 'About Us',
            class: 'fs--1',
            link: '/about-us/'
        },
        {
            icon: '',
            text: 'Courses Offered',
            link: '/course-offered/'
        },
        {
            icon: '',
            text: 'Training Centers',
            link: '/training-centers/'
        },
        {
            icon: '',
            text: 'Contact Us',
            link: '/contact/'
        },
        {
            icon: '',
            text: 'News',
            link: '/news/'
        }
    ];
    if (user && user.name) {
        menu.push({
            icon: 'fa fa-user',
            class: 'ml-auto text-capitalize user-menu',
            text: user.name,
            items: [
                {
                    text: 'Profile Update',
                    link: '/profile-update/',
                    class: 'profile-update-menu'
                },
                {
                    text: 'My Courses',
                    link: user.user_type === 'Organization' ? '/organization-courses/' : '/my-courses/',
                    class: 'my-courses-menu'
                },
                {
                    text: 'Logout',
                    event: 'logout',
                    class: 'bg-primary logout-menu'
                }
            ]
        });
    } else {
        menu.push({
            text: 'LOGIN NOW',
            link: '/login/',
            type: 'button',
            class: 'nav-btn-cont',
            btnAttrs: {
                design: 'basic-b',
                borderRadius: '0',
                color: 'white',
                class: 'round-nav-btn-r login-btn ml-lg-5 mt-4 mt-lg-0'
            }
        });
    }
    return [
        ...menu
    ];
};
export default getMenu;
