<template>
    <div class="" id="top">
        <div class="nav-container">
            <notifications/>
            <lego-nav-bar drop-down-animation="fade" drop-down-animation-mobile="fade"
                          :colored="true" :items="menu" @link-event="linkEvent"
                          shadow="0" class="w-90p">
                <template #logo>
                    <router-link to="/">
                        <img src="../assets/img/logo/logo.png" class="logo-img" alt="">
                    </router-link>
                </template>
            </lego-nav-bar>
        </div>

        <div class="bg-2">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
        <main-footer></main-footer>
    </div>

</template>

<script>
// import LegoNavBar from 'lego-framework/src/components/NavBar';
import LegoNavBar from '../components/lego-custom/NavBar';
import getMenu from '../data/menu';
import MainFooter from '@components/MainFooter';
import urls from '../data/urls';
import axios from 'secure-axios';

export default {
    components: {
        MainFooter,
        LegoNavBar
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout',
    methods: {
        linkEvent (name) {
            if (name === 'logout') {
                localStorage.clear();
                axios.get(urls.auth.logout);
                this.$router.push('/login/');
            }
        }
        // afterLeave () {
        //     console.log('trigger scroll');
        //     this.$root.$emit('triggerScroll');
        // }
    }
};
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }
</style>
